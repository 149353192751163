





































































import SKCarousel from '@/components/SK-Carousel.vue';
import SKFooter from '@/components/SK-Footer.vue';
import SKHeader from '@/components/SK-Header.vue';
import SKHero from '@/components/SK-Hero.vue';
import SKPosition from '@/components/SK-Position.vue';
import { copyToClipboard } from '@/utils/functions';
import { ISKNotification } from '@/utils/interfaces';
import { getText } from '@/utils/langs';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    SKFooter,
    SKHeader,
    SKCarousel,
    SKPosition,
    SKHero
  }
})
export default class Home extends Vue {
  public share(): void {
    const url = window.location.href;
    const share: ShareData = {
      title: 'SportKamasutra',
      text: getText(`ui.home.title`),
      url: url
    };

    try {
      navigator
        .share(share)
        .then(() => {
          /** */
        })
        .catch(this.copyToClip);
    } catch (_) {
      this.copyToClip();
    }
  }

  private copyToClip() {
    copyToClipboard(window.location.href);
    this.$store.commit('sendNotification', {
      text: window.location.href,
      title: 'Copied URL to your clipboard'
    } as ISKNotification);
  }
}
